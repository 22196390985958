import { createContext } from 'react';

export const InitialPropsProviderDefault = {
  menus: {
    primaryMenu: { menus: [] },
    legal: { menus: [] },
    recommended: { menus: [] },
    about: { menus: [] },
    support: { menus: [] },
    extra: { menus: [] },
  },
  options: {
    ajustesGenerales: {
      logo: {},
      logoMobile: {},
      newsletter: {
        tituloNewsletter: '',
        textoNewsletter: '',
      },
      porQueComprar: {
        titulo: '',
        items: [],
      },
      copyright: '',
      socialNetworks: [],
      payments: [],
      footerContent: [],
      legalText: '',
      suggestions: [],
      link: {
        label: '',
        link: {
          url: '',
        },
        style: '',
        size: '',
      },
      numMaxProduct: 7,
      weightMaxCart: 85,
    },
    newsletter: null,
  },
  cart: { contents: { itemCount: 0 } },
};

export const InitPropsProvider = createContext(
  InitialPropsProviderDefault, // valor por defecto
);
