import Router from 'next/router';

import { findIsChildOfBundleInOrder } from '@/lib/graphql/transformers/order/order';
import { OPERATOR } from '../orders';
import { getDiscountAmount, priceToNumber } from '../price';
import { isBundle } from '../product';
import { getRealURL, isOn } from '../utils';
import { AFFILIATION_NAME_OPERATOR, getProductCategoriesTree } from './helpers';
import { getID, getPrices } from './normalizeOrderItems';

import type { TypeGTMProduct, TypeMetaDatas } from '@/types';

export const normalizeCartItems = (
  data: any,
  type: 'analytics' | 'connectif' = 'analytics',
): any => {
  //TODO: coger el cupon anadido a cada producto
  //TODO: anadir productos También te puede interesar
  //TODO: anadir la marca del producto --to discuss with the team
  const cartItems: any[] = data?.contents?.edges;
  const items: Array<TypeGTMProduct> = [];

  if (cartItems) {
    cartItems.forEach((element, index) => {
      const metaData = element.node.extraData as TypeMetaDatas;
      const childOfBundle = metaData?.find(findIsChildOfBundleInOrder);
      const boxVariantQuantity = metaData?.find(
        (meta) => meta.key === 'woosb_variant_quantity',
      );
      const autoCalculatePrice = isOn(
        element.node.product.node?.woosbOptionalProducts,
      );
      const isCustomBoxParent =
        isBundle(element.node.product.node.type) && autoCalculatePrice;
      const isCustomBoxChild =
        childOfBundle && boxVariantQuantity && !autoCalculatePrice;
      const productVariation = element.node.variation;
      const product = productVariation
        ? productVariation.node
        : element.node.product.node;
      const onSale = element.node.product.node.onSale;
      const discount = onSale
        ? Number.parseFloat(
            getDiscountAmount(
              priceToNumber(product.regularPrice),
              priceToNumber(product.salePrice),
            ).toFixed(2),
          )
        : null;
      const brand =
        element.node.product.node.caracteriSticasDelProducto.datosDeProducto
          .marca;
      const seller =
        product.sellerInfo?.name === OPERATOR
          ? AFFILIATION_NAME_OPERATOR
          : product.sellerInfo?.name;
      const quantity = element.node.quantity;
      const image = element.node.product.node.image?.sourceUrl ?? null;
      const url = element.node.product.node?.link
        ? getRealURL(element.node.product.node.link)
        : null;

      let discountGTM = onSale && discount ? discount * quantity : null;
      let price = priceToNumber(product.regularPrice) * quantity;

      if (isCustomBoxParent) {
        const prices = getPrices(element.node.key, cartItems, 'extraData');
        price = prices.price;
        discountGTM = prices.discount;
      }

      if (isCustomBoxChild) {
        price = 0;
        discountGTM = 0;
      }

      let item: TypeGTMProduct = {
        item_id: type === 'connectif' ? product.databaseId : product.sku,
        item_name: product.name,
        affiliation: seller,
        currency: 'EUR',
        discount: discountGTM,
        index,
        item_brand: Array.isArray(brand) ? brand[0]?.slug : null,
        item_list_name:
          (Router.query?.itemListName as string | undefined) ?? null,
        item_list_id: (Router.query?.itemListId as string | undefined) ?? null,
        item_variant: productVariation
          ? product.sku
          : autoCalculatePrice && !childOfBundle
            ? boxVariantQuantity?.value
            : null,
        price,
        quantity,
        image,
        url,
        ...(isCustomBoxChild && {
          custom_box: true,
          ...(boxVariantQuantity && {
            custom_box_variant: boxVariantQuantity.value,
          }),
        }),
        ...(isCustomBoxParent && {
          custom_box_items: getID(element.node.key, cartItems, 'extraData'),
        }),
      };

      if (element.node.product.node.productCategories) {
        const categories = getProductCategoriesTree(element.node.product.node);

        item = {
          ...item,
          ...categories,
        };
      }

      items.push(item);
    });
  }

  return items;
};
