import type { TypeImageGraphQL } from '@/lib/graphql/types';
import type { ImageProps } from 'next/future/image';

type TypeImage = {
  src: ImageProps['src'];
  alt: ImageProps['alt'];
  width: ImageProps['width'];
  height: ImageProps['height'];
};

export const imageTransformer = (image: TypeImageGraphQL): TypeImage => ({
  height: image?.mediaDetails?.height,
  src: image?.sourceUrl,
  width: image?.mediaDetails?.width,
  alt: image?.altText ?? '',
});
